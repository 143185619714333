import React, { useState, useEffect, useRef } from "react";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import "../Gallery/Gallery.css";

const Gallery = ({
  gallery_data,
  uniqueMonths,
  uniqueYears,
  photoGallery,
  photoTabs,
}) => {
  const galleryRef = useRef(null);

  const [selectedMonth, setSelectedMonth] = useState(uniqueMonths[0]);
  const [selectedYear, setSelectedYear] = useState(uniqueYears[0]);
  const [monthsWithData, setMonthsWithData] = useState(uniqueMonths);
  const [yearsWithData, setYearsWithData] = useState(uniqueYears);
  const [noDataMessage, setNoDataMessage] = useState('');

  useEffect(() => {
    const months = uniqueMonths.filter((month) => {
      return gallery_data.some((galData) => {
        const date = new Date(galData.datemonth);
        const monthStr = date
          .toLocaleString("default", { month: "short" })
          .toUpperCase();
        const year = date.getFullYear().toString();
        return monthStr === month && year === selectedYear;
      });
    });

    setMonthsWithData(months);

    if (!months.includes(selectedMonth)) {
      setSelectedMonth(months.length > 0 ? months[0] : '');
    }
  }, [selectedYear, uniqueMonths, gallery_data, selectedMonth]);

  useEffect(() => {
    const years = uniqueYears.filter((year) => {
      return gallery_data.some((galData) => {
        const date = new Date(galData.datemonth);
        return date.getFullYear().toString() === year;
      });
    });

    setYearsWithData(years);

    if (!years.includes(selectedYear)) {
      setSelectedYear(years.length > 0 ? years[0] : '');
    }
  }, [uniqueYears, gallery_data, selectedYear]);

  const filteredData = gallery_data.filter((galData) => {
    const date = new Date(galData.datemonth);
    const month = date
      .toLocaleString("default", { month: "short" })
      .toUpperCase();
    const year = date.getFullYear().toString();
    return month === selectedMonth && year === selectedYear;
  });

  useEffect(() => {
    if (!filteredData.length) {
      setNoDataMessage(`No data available for ${selectedMonth} ${selectedYear}`);
    } else {
      setNoDataMessage('');
    }
  }, [filteredData, selectedMonth, selectedYear]);

  return (
    <div className="column9">
      {photoTabs && (
        <div className="upper__tabs mb-5 d-grid justify-content-between align-items-center">
         
          {uniqueYears.length > 1 && (
            <div className="col-Years">
              <ul className="d-flex px-5">
                {yearsWithData.map((year) => (
                  <li
                    key={year}
                    className={selectedYear === year ? "active" : ""}
                    onClick={() => setSelectedYear(year)}
                  >
                    {year}
                  </li>
                ))}
              </ul>
            </div>
          )}
           <div className="col-Months">
            <ul className="d-flex px-5">
              {monthsWithData.map((month) => (
                <li
                  key={month}
                  className={selectedMonth === month ? "active" : ""}
                  onClick={() => setSelectedMonth(month)}
                >
                  {month}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      <div className="lightGallerybox" ref={galleryRef}>
        {noDataMessage ? (
          <p>{noDataMessage}</p>
        ) : photoGallery ? (
          <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
            {filteredData.map((galData, index) => (
              <a key={index} href={galData.imageUrl}>
                <div className="galleryimg">
                  <img
                    src={galData.thumbnailUrl}
                    alt={`${galData.news_paperName} | ${galData.datemonth}`}
                  />
                </div>
                <div className="lightcont">
                  <h4 className="m-0 heading5 fw-600 mb-2">
                    {galData.news_paperName}
                  </h4>
                  <h5 className="m-0">{galData.datemonth}</h5>
                </div>
              </a>
            ))}
          </LightGallery>
        ) : (
          <div className="col-12 float-start">
            <div className="row">
              {filteredData.map((galData, index) => (
                <div className="col-lg-6 col-sm-6 col-12" key={index}>
                  <div className="Videobox">
                    <div className="db-thumb gallery">
                      <iframe
                        width="100%"
                        height="250"
                        src={galData.videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                    <div className="Videocont">
                      <h4>{galData.titleData}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
