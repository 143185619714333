import React from 'react';

const SVGComponent = ({ data }) => {
    const cleanData = data.replace(/^"(.*)"$/, '$1');

  return (
    <>
      <p dangerouslySetInnerHTML={{ __html: cleanData }} className="m-0" />
    </>
  );
};

export default SVGComponent;
